export const selectedStatuses = (
  invoices: PartnerInvoice[] | Invoice[],
  selectedRowKeys: React.Key[]
) => {
  const filtered = invoices.filter((invoice) =>
    selectedRowKeys.includes(invoice.id)
  );

  return [
    ...new Set(
      filtered.map((invoice) => ({
        externalStatus: invoice.externalStatus,
        paidStatus: invoice.paidStatus,
      }))
    ),
  ];
};

export const transformForInvoiceActions = (
  invoices: Invoice[],
  selectedRowKeys: React.Key[]
): {
  tableIds: string[];
} => {
  const filtered = invoices.filter((lineItem) =>
    selectedRowKeys.includes(lineItem.id)
  );

  const invoiceTableIds = filtered.map((invoice) => invoice.id);

  return {
    tableIds: invoiceTableIds,
  };
};

export const transformForNewPayment = (
  invoices: Invoice[],
  selectedRowKeys: React.Key[]
): {
  selectedSiteTrialId: string;
  rowKeys: string[];
} => {
  const filtered = invoices.filter((lineItem) =>
    selectedRowKeys.includes(lineItem.id)
  );

  const [first] = filtered;

  const [lineItem] = first.lineItems;

  // source of rowKey creation in usePayments
  // invoiceId|adHocReceivableId|patientProtocolVisitId|protocolActivityCrossVersionId
  const invoiceTableIds = filtered.map(
    (invoice) => `${invoice.id}|null|null|null`
  );

  return {
    selectedSiteTrialId: lineItem.siteTrial.id,
    rowKeys: invoiceTableIds,
  };
};
