import {
  AllocatedPaymentAllocationTableItem,
  allocatePaymentAllocationTableItem,
  calculatePercentage,
  createPaymentAllocationTableItem,
  deallocatePaymentAllocationTableItem,
  isAllocatedPaymentAllocationTableItem,
  PaymentAllocationTableItem,
  updatePaymentAllocationTableItemAmount,
} from "@model/payments";
import { useOpenPayableItemsQuery } from "@app/service/generated";
import { plus, Money, money, minus, toGraphQLInput } from "@lib/currency";
import { FormInstance } from "@reifyhealth/picasso-pkg";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export enum PaymentAttachmentType {
  TEMP,
  EXISTING,
}

function defaultTableSort(
  a: PaymentAllocationTableItem,
  b: PaymentAllocationTableItem
): number {
  const aAlloc = isAllocatedPaymentAllocationTableItem(a);
  const bAlloc = isAllocatedPaymentAllocationTableItem(b);

  if (aAlloc && !bAlloc) {
    return -1;
  }

  if (aAlloc && bAlloc) {
    if (a.status === b.status) {
      return new Date(a.dueDate) < new Date(b.dueDate) ? -1 : 1;
    } else if (a.status === "PAST_DUE") {
      return -1;
    } else if (a.status === "OPEN" && b.status === "PAID") {
      return -1;
    }
  }

  if (!aAlloc && !bAlloc) {
    if (a.status === b.status) {
      return new Date(a.dueDate) < new Date(b.dueDate) ? -1 : 1;
    } else if (a.status === "PAST_DUE") {
      return -1;
    }
  }

  return 1;
}

export const usePayments = (
  form: FormInstance<{
    siteTrialId: string | null;
    partnerId: string | null;
    amount: Money | null;
    date: string;
    memo: string | null;
    reference: string | null;
  }>
) => {
  const location = useLocation();
  const [paymentAllocationEnabled, setPaymentAllocationEnabled] =
    useState(false);
  const params = useParams();
  const [amount, setAmount] = useState<Money | null>(null);
  const [remainingAmount, setRemainingAmount] = useState<Money | null>(null);

  const [selectedAttachments, setSelectedAttachments] = useState<
    {
      contentType: string;
      filename: string;
      id: string;
      type: PaymentAttachmentType;
    }[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [paymentItems, setPaymentItems] = useState<
    PaymentAllocationTableItem[]
  >([]);

  const selectedPaymentAllocations = useMemo(
    () => paymentItems.filter(isAllocatedPaymentAllocationTableItem),
    [paymentItems]
  );

  const isRecordButtonDisabled = selectedPaymentAllocations.some(
    (item) => item.clientAllocation === null
  );

  const selectedRowKeys = useMemo(
    () => selectedPaymentAllocations.map((wrappedItem) => wrappedItem.rowKey),
    [selectedPaymentAllocations]
  );

  const [percentage, setPercentage] = useState(0);
  const handleSelectChange = (selectedRowKeys: React.Key[]) => {
    let keySet = new Set(selectedRowKeys);

    let newPaymentItems = paymentItems.map((wrappedItem) => {
      const key = wrappedItem.rowKey;
      const clientAllocated =
        isAllocatedPaymentAllocationTableItem(wrappedItem);

      if (keySet.has(key) && !clientAllocated) {
        return allocatePaymentAllocationTableItem(wrappedItem, remainingAmount);
      } else if (!keySet.has(key) && clientAllocated) {
        return deallocatePaymentAllocationTableItem(wrappedItem);
      }

      return wrappedItem;
    });

    setPaymentItems(newPaymentItems);
  };

  const {
    isLoading: isLoadingOpenPayableItems,
    isFetching: isFetchingOpenPayableItems,
    refetch: refetchOpenPayableItems,
  } = useOpenPayableItemsQuery(
    {
      siteTrialId: form.getFieldValue("siteTrialId"),
      paymentId: params.paymentId || null,
    },
    {
      enabled: paymentAllocationEnabled,
      onSuccess: (data) => {
        const paymentAllocations = data?.Financials2__openPayableItems.items;

        const receivedData: {
          selectedPartnerId: string;
          selectedSiteTrialId: string;
          rowKeys: string[];
        } | null = location.state?.data || null;

        if (
          receivedData &&
          receivedData.rowKeys &&
          receivedData.rowKeys.length > 0
        ) {
          const preselectedItems = new Set(receivedData?.rowKeys || []);

          const enhancedPaymentAllocations = (paymentAllocations || []).map(
            (incomingItem) => {
              const enhanced = createPaymentAllocationTableItem(incomingItem);

              if (
                preselectedItems.has(enhanced.rowKey) &&
                !isAllocatedPaymentAllocationTableItem(enhanced)
              ) {
                return allocatePaymentAllocationTableItem(
                  enhanced,
                  enhanced.paymentItemMaxAllocationAmount
                    ? money(enhanced.paymentItemMaxAllocationAmount)
                    : null
                );
              }

              return enhanced;
            }
          );

          const allocationSum = enhancedPaymentAllocations.reduce(
            (acc, item) => {
              if (isAllocatedPaymentAllocationTableItem(item)) {
                return plus(acc, item.clientAllocation!);
              }
              return acc;
            },
            money(0)
          );

          enhancedPaymentAllocations.sort(defaultTableSort);

          setAmount(allocationSum);
          form.setFieldsValue({ amount: allocationSum });

          setPaymentItems(enhancedPaymentAllocations);
        } else {
          const enhancedPaymentAllocations = (paymentAllocations || []).map(
            createPaymentAllocationTableItem
          );

          enhancedPaymentAllocations.sort(defaultTableSort);

          setPaymentItems(enhancedPaymentAllocations);
        }
      },
    }
  );

  useEffect(() => {
    const receivedData: {
      selectedPartnerId: string;
      selectedSiteTrialId: string | null;
      rowKeys: string[];
    } | null = location.state?.data || null;

    if (receivedData) {
      form.setFieldsValue({
        siteTrialId: receivedData.selectedSiteTrialId || null,
        partnerId: receivedData.selectedPartnerId,
      });

      if (receivedData.selectedSiteTrialId) {
        setPaymentAllocationEnabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    const allocatedAmount = selectedPaymentAllocations.reduce((acc, curr) => {
      if (
        isAllocatedPaymentAllocationTableItem(curr) &&
        curr.clientAllocation
      ) {
        return plus(acc, curr.clientAllocation);
      }

      return acc;
    }, money(0));

    setPercentage(
      +calculatePercentage(allocatedAmount, amount || money(0)).toFixed(4)
    );

    setRemainingAmount(amount ? minus(amount, allocatedAmount) : null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPaymentAllocations]);

  useEffect(() => {
    const allocatedAmount = selectedPaymentAllocations.reduce((acc, curr) => {
      if (
        isAllocatedPaymentAllocationTableItem(curr) &&
        curr.clientAllocation
      ) {
        return plus(acc, curr.clientAllocation);
      }

      return acc;
    }, money(0));

    setPercentage(
      +calculatePercentage(allocatedAmount, amount || money(0)).toFixed(4)
    );

    setRemainingAmount(amount ? minus(amount, allocatedAmount) : null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount]);

  // Used when an end user overrides a default full amount of a given entry
  function setAmountOverride(id: string, amountOverride: string | null) {
    const newPaymentItems = paymentItems.map((wrappedItem) => {
      if (
        id === wrappedItem.rowKey &&
        isAllocatedPaymentAllocationTableItem(wrappedItem)
      ) {
        let result = updatePaymentAllocationTableItemAmount(
          wrappedItem,
          amountOverride,
          remainingAmount!
        );
        if (typeof result === "string") {
          throw result;
        } else {
          return result as AllocatedPaymentAllocationTableItem;
        }
      }

      return wrappedItem;
    });

    setPaymentItems(newPaymentItems);
  }

  return {
    paymentAllocations: paymentItems,
    paymentAllocationsAreLoading:
      isLoadingOpenPayableItems || isFetchingOpenPayableItems,
    isRecordButtonDisabled,
    setPaymentAllocationEnabled,
    paymentAllocationEnabled,
    handleSelectChange,
    selectedRowKeys,
    amount,
    setAmount,
    percentage,
    setRemainingAmount,
    remainingAmount,
    selectedPaymentAllocationsMutationInput: selectedPaymentAllocations
      .filter((item) => item.clientAllocation)
      .map((wrappedItem) => {
        let {
          invoiceId,
          adHocReceivableId,
          patientProtocolVisitId,
          protocolActivityCrossVersionId,
          type,
        } = wrappedItem;

        let input: IFinancials2__PaymentAllocationInput = {
          amount: toGraphQLInput(wrappedItem.clientAllocation!),
          invoiceId,
          adHocReceivableId,
          patientProtocolVisitId,
          protocolActivityCrossVersionId,
          type,
        };

        return input;
      }),
    setAmountOverride,
    refetchOpenPayableItems,
    searchTerm,
    setSearchTerm,
    setSelectedAttachments,
    selectedAttachments,
  };
};
