import { Avatar, Dropdown, Menu, icons } from "@reifyhealth/picasso-pkg";
import { styled } from "@linaria/react";
import { useAuth0 } from "@reifyhealth/picasso-auth0";
import { Link } from "react-router-dom";
import { isDevEnabled } from "@lib/environment";

const iconSize = 32;

const Icon = styled.div`
  svg {
    fill: var(--application-primary-text);
  }
`;

const WaffleIcon = () => {
  return (
    <Icon>
      <svg viewBox={`0 0 ${iconSize} ${iconSize}`}>
        <path
          d="M15.0791 13.6254C15.0791 14.4282 14.4283 15.079 13.6255 15.079C12.8226 15.079 12.1718 14.4282 12.1718 13.6254C12.1718 12.8225 12.8226 12.1717 13.6255 12.1717C14.4283 12.1717 15.0791 12.8225 15.0791 13.6254Z"
          strokeWidth="0.0934554"
        />
        <path
          d="M21.4542 13.6254C21.4542 14.4282 20.8034 15.079 20.0005 15.079C19.1977 15.079 18.5468 14.4282 18.5468 13.6254C18.5468 12.8225 19.1977 12.1717 20.0005 12.1717C20.8034 12.1717 21.4542 12.8225 21.4542 13.6254Z"
          strokeWidth="0.0934554"
        />
        <path
          d="M27.8292 13.6254C27.8292 14.4282 27.1784 15.079 26.3755 15.079C25.5727 15.079 24.9218 14.4282 24.9218 13.6254C24.9218 12.8225 25.5727 12.1717 26.3755 12.1717C27.1784 12.1717 27.8292 12.8225 27.8292 13.6254Z"
          strokeWidth="0.0934554"
        />
        <path
          d="M15.0791 20.0004C15.0791 20.8033 14.4283 21.4541 13.6255 21.4541C12.8226 21.4541 12.1718 20.8033 12.1718 20.0004C12.1718 19.1976 12.8226 18.5468 13.6255 18.5468C14.4283 18.5468 15.0791 19.1976 15.0791 20.0004Z"
          strokeWidth="0.0934554"
        />
        <path d="M20.0005 21.5008C20.8292 21.5008 21.5009 20.8291 21.5009 20.0004C21.5009 19.1718 20.8292 18.5 20.0005 18.5C19.1719 18.5 18.5001 19.1718 18.5001 20.0004C18.5001 20.8291 19.1719 21.5008 20.0005 21.5008Z" />
        <path d="M26.3755 21.5008C27.2042 21.5008 27.8759 20.8291 27.8759 20.0004C27.8759 19.1718 27.2042 18.5 26.3755 18.5C25.5469 18.5 24.8751 19.1718 24.8751 20.0004C24.8751 20.8291 25.5469 21.5008 26.3755 21.5008Z" />
        <path d="M13.6255 27.8758C14.4541 27.8758 15.1258 27.2041 15.1258 26.3754C15.1258 25.5468 14.4541 24.875 13.6255 24.875C12.7968 24.875 12.1251 25.5468 12.1251 26.3754C12.1251 27.2041 12.7968 27.8758 13.6255 27.8758Z" />
        <path d="M20.0005 27.8758C20.8292 27.8758 21.5009 27.2041 21.5009 26.3754C21.5009 25.5468 20.8292 24.875 20.0005 24.875C19.1719 24.875 18.5001 25.5468 18.5001 26.3754C18.5001 27.2041 19.1719 27.8758 20.0005 27.8758Z" />
        <path d="M26.3755 27.8758C27.2042 27.8758 27.8759 27.2041 27.8759 26.3754C27.8759 25.5468 27.2042 24.875 26.3755 24.875C25.5469 24.875 24.8751 25.5468 24.8751 26.3754C24.8751 27.2041 25.5469 27.8758 26.3755 27.8758Z" />
      </svg>
    </Icon>
  );
};

const avatarDropdownMenu = (onLogoutClickFn: Function) => {
  return (
    <Menu>
      <Menu.Item
        data-testid="logout"
        key="logout"
        onClick={() => onLogoutClickFn()}
      >
        Logout
      </Menu.Item>
    </Menu>
  );
};

const waffleDropdownMenu = (
  <Menu>
    <Menu.Item>
      <Link
        data-testid="sites-link"
        to={`${window.location.origin}/site`}
        target="_blank"
      >
        StudyTeam for Sites
      </Link>
    </Menu.Item>
    <Menu.Item data-testid="esource-link">
      <Link to={`${window.location.origin}/documents`} target="_blank">
        StudyTeam eSource
      </Link>
    </Menu.Item>
  </Menu>
);

const UserControlStyles = styled.div`
  display: flex;
  align-items: center;
  gap: var(--space-4);
  height: 100%;
  padding: 0 var(--space-6) 0 var(--space-4);
  border-left: 1px solid var(--component-border);

  svg,
  .ant-avatar {
    cursor: pointer;
  }

  .ant-avatar {
    background: var(--avatar-background);
    color: var(--avatar-text);
    margin-left: var(--space-3);
  }

  .user-control-icon {
    svg {
      width: 32px;
    }
  }
`;

interface UserControlsProps {
  avatarName?: string;
}

export const UserControls = (_props: UserControlsProps) => {
  const auth = useAuth0();

  return (
    <UserControlStyles>
      <Dropdown overlay={waffleDropdownMenu}>
        <div className="user-control-icon">
          <WaffleIcon />
        </div>
      </Dropdown>
      <Dropdown
        overlay={avatarDropdownMenu(() =>
          auth.logout({
            logoutParams: {
              returnTo: isDevEnabled()
                ? window.location.origin
                : `${window.location.origin}/finance`,
            },
          })
        )}
      >
        <Avatar data-testid="avatar" icon={<icons.UserOutlined />} />
      </Dropdown>
    </UserControlStyles>
  );
};
