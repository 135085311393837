import { PageLayout } from "@app/components/PageLayout";
import { styled } from "@linaria/react";
import { css } from "@linaria/core";
import { Button, Form, message, PageHeader } from "@reifyhealth/picasso-pkg";
import { useNavigate, useParams } from "react-router-dom";
import { EditPaymentForm } from "@app/components/forms";
import { PaymentChart } from "@app/components/charts/PaymentChart";
import { PaymentAllocationTable } from "@app/components/tables";
import { lte, Money, money } from "@lib/currency";
import { usePayments } from "@app/hooks/usePayments";
import { useGetPaymentNumberQuery } from "@app/service/generated";
import { useState } from "react";
import { PaymentAttachmentsUploader } from "@app/components/uploader";

const paymentViewWrapper = css`
  min-width: 860px;
  max-width: 80%;
  margin: 0 auto;
`;

const PaymentCreationHeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;

  .lead-instructions {
    font-size: 16px;
    color: var(--component-secondary-text);
    margin-bottom: var(--size-8);
  }

  .title-container {
    display: flex;
    gap: var(--size-6);
    align-items: center;
    margin-bottom: var(--size-4);

    .title {
      font-size: 23px;
      font-weight: var(--font-weight-medium);
      color: var(--component-primary-text);
    }

    .payment-number {
      color: var(--component-secondary-text, #687076);
      font-size: var(--font-size-footnote);
      font-weight: var(--font-weight-normal);
    }
  }
`;

const paymentWrapper = css`
  padding: var(--size-8);
  border-radius: var(--border-2);
  border: 1px solid var(--component-border);
  background: var(--component-background);
  min-width: 935px;
`;

const formElements = css`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: var(--size-8);
  gap: var(--size-6);

  .flex-item {
    flex: 0 0 auto;
  }

  .flex-item.upload {
    width: 200px;

    .ant-upload.ant-upload-drag {
      height: unset;
    }

    .upload-icon {
      width: var(--size-10);
      height: var(--size-10);
      color: var(--shared-info);
    }
  }

  .configure {
    flex: 1 1 auto;
    width: 50%;

    .ant-form {
      .ant-form-item {
        margin-bottom: var(--size-4);
      }
    }

    .memo-field {
      padding: var(--size-6);
      background: var(--component-background-subtle);

      .ant-typography-edit-content {
        left: 0;
        margin: 0;

        .ant-input {
          line-height: unset;
          position: unset;
        }
      }
    }
  }

  .result {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 200px;
    text-align: center;
    border-left: 1px solid var(--component-border);
    padding: var(--size-6);

    .ant-progress {
      margin-bottom: var(--size-4);
    }

    h5.ant-typography {
      margin-bottom: 0;
    }
  }
`;

const emptyContainer = css`
  padding: 16px;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  background: var(--component-background-subtle, #f8f9fa);
  color: var(--component-tertiary-text, #7e868c);
  text-align: center;
`;

const footer = css`
  margin-top: var(--size-8);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const PaymentDetailsPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm<{
    siteTrialId: string | null;
    partnerId: string | null;
    amount: Money | null;
    date: string;
    memo: string | null;
    reference: string | null;
  }>();

  const {
    paymentAllocationsAreLoading,
    setPaymentAllocationEnabled,
    paymentAllocationEnabled,
    paymentAllocations,
    handleSelectChange,
    selectedRowKeys,
    setAmount,
    amount,
    percentage,
    setAmountOverride,
    selectedPaymentAllocationsMutationInput: selectedPaymentAllocations,
    remainingAmount,
    searchTerm,
    setSearchTerm,
    setSelectedAttachments,
    selectedAttachments,
    isRecordButtonDisabled,
  } = usePayments(form);

  const [disableRecordPayment, setDisableRecordPayment] = useState(false);

  const { data } = useGetPaymentNumberQuery({ paymentId: params.paymentId! });

  return (
    <PageLayout>
      <section className={paymentViewWrapper}>
        <PaymentCreationHeaderContainer>
          <PageHeader
            style={{ padding: 0, width: "100%" }}
            title="Edit Payment"
            extra={
              <Button
                onClick={async () => {
                  // used only for UI elements (manual validation fires off proper error states in form)
                  await form.validateFields().catch(() => null);

                  const noErrorExistsInAnyField = form
                    .getFieldsError()
                    .every((field) => field.errors.length === 0);

                  if (noErrorExistsInAnyField) {
                    form.submit();
                  } else {
                    message.error("Validation failed!");
                  }
                }}
                disabled={
                  isRecordButtonDisabled ||
                  percentage > 100 ||
                  paymentAllocationsAreLoading ||
                  !amount ||
                  lte(amount, 0)
                }
                htmlType="submit"
                type="primary"
              >
                Update Payment
              </Button>
            }
            onBack={() => navigate(`/finance/sites/${params.siteId}/payments`)}
            subTitle={
              data?.Financials2__paymentDetails.payment.paymentNumber && (
                <span className="payment-number">
                  Payment #:{" "}
                  {data?.Financials2__paymentDetails.payment.paymentNumber}
                </span>
              )
            }
          >
            <p className="lead-instructions">
              Open Invoices and Receivables can be applied to payments.
            </p>
          </PageHeader>
        </PaymentCreationHeaderContainer>
        <section className={paymentWrapper}>
          <section className={formElements}>
            <section className="flex-item upload">
              <PaymentAttachmentsUploader
                resetFileList={false}
                setSelectedAttachments={setSelectedAttachments}
                selectedAttachments={selectedAttachments}
              />
            </section>
            <section className="flex-item configure">
              <EditPaymentForm
                form={form}
                paymentId={params.paymentId!}
                setAmountFn={setAmount}
                selectedPaymentAllocations={selectedPaymentAllocations}
                setPaymentAllocationEnabled={setPaymentAllocationEnabled}
                setDisableRecordPayment={setDisableRecordPayment}
                selectedAttachments={selectedAttachments}
                setSelectedAttachments={setSelectedAttachments}
              />
            </section>
            <section className="flex-item result">
              <PaymentChart
                amount={amount ?? money(0)}
                percentage={percentage}
                remainingAmount={remainingAmount}
                showInfo={paymentAllocationEnabled}
              />
            </section>
          </section>
          {paymentAllocationEnabled ? (
            <PaymentAllocationTable
              paymentAllocations={paymentAllocations}
              tableIsLoading={paymentAllocationsAreLoading}
              handleSelectChange={handleSelectChange}
              selectedRowKeys={selectedRowKeys}
              searchTerm={searchTerm}
              onSearchTermChange={setSearchTerm}
              setAmountOverrideFn={setAmountOverride}
              paymentRemainingAmount={remainingAmount}
            />
          ) : (
            <section className={emptyContainer}>
              Choose a Trial for this payment
            </section>
          )}
        </section>
        <section className={footer}>
          <section>
            <Button
              onClick={() =>
                navigate(`/finance/sites/${params.siteId}/payments`)
              }
              data-testid="edit-payment-cancel-button"
            >
              Cancel
            </Button>
          </section>
          <section>
            <Button
              onClick={async () => {
                // used only for UI elements (manual validation fires off proper error states in form)
                await form.validateFields().catch(() => null);

                const noErrorExistsInAnyField = form
                  .getFieldsError()
                  .every((field) => field.errors.length === 0);

                if (noErrorExistsInAnyField) {
                  form.submit();
                } else {
                  message.error("Validation failed!");
                }
              }}
              disabled={
                isRecordButtonDisabled ||
                percentage > 100 ||
                paymentAllocationsAreLoading ||
                disableRecordPayment ||
                !amount ||
                lte(amount, 0)
              }
              htmlType="submit"
              type="primary"
            >
              Update Payment
            </Button>
          </section>
        </section>
      </section>
    </PageLayout>
  );
};
